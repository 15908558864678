<template>
  <v-card flat>
    <v-container class="pa-12">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-text-field
          v-model="email"
          :rules="emailRules"
          label="E-mail"
          outlined
          placeholder=""
          type="email"
          required
          rounded
          @keyup.enter="validate"
        />

        <v-text-field
          v-model="password"
          :rules="passwordRules"
          label="Senha"
          type="password"
          required
          outlined
          rounded
          @keyup.enter="validate"
        />

        <v-row v-if="error || !valid" class="pb-6">
          <v-col cols="12">
            <div class="red--text text-center aling-center">
              {{ error }}
            </div>
          </v-col>
        </v-row>

        <v-btn
          :loading="loading"
          color="orange darken-3"
          :dark="valid || !loading"
          class="mr-4"
          block
          rounded
          large
          @click.prevent="validate"
        >
          Entrar
        </v-btn>
        <v-row class="pa-6">
          <v-col cols="12">
            <div class="blue--text text-center aling-center">
              <router-link to="/lembrar-senha">Esqueci minha senha</router-link>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </v-card>
</template>
<script>
  import { mapActions } from 'vuex'
  import { getUser } from '../../services/users'
  export default {
    name: 'LoginForm',
    data: () => ({
      valid: true,
      email: '',
      emailRules: [v => !!v || "Por favor, informe o seu email", v => /.+@.+/.test(v) || "E-mail precisa ser válido"],
      password: '',
      passwordRules: [
        v => !!v || "Por favor, informe sua senha",
        v => v.length >= 8 || "Por favor, sua senha deve conter no mínimo 8 caracteres."
      ],
      error: null,
      loading: false,
    }),

    methods: {
      ...mapActions(['login']),
      validate () {
        if (!this.$refs.form.validate()) {
          this.error = 'Informe os dados solicitados'
          return
        }
        this.loading = true
        const formData = {
          email: this.email,
          password: this.password
        }
        this.login(formData)
          .then(result => {
            if (result.code) {
              this.loading = false
              if (result.code === "auth/wrong-password") {
                this.error = "Ops! Senha inválida."
                return
              } else if (result.code === "auth/user-not-found") {
                this.error = "Ops! Email não encontrado em nossa base, tem certeza que se cadastrou com esse e-mail?"
                return
              } else if (result.code === "auth/too-many-request") {
                this.error = "Ops! O acesso a esta conta foi temporariamente desativado devido a muitas tentativas de login malsucedidas. Você pode restaurá-lo imediatamente redefinindo sua senha ou pode tentar novamente mais tarde."
                return
              } else {
                this.error = "Ops! " + result.code + ": " + result.message
                return
              }
            } else {
              if (result) {
                // this.$store.commit("user", result.user)
                getUser(result.user.uid)
                  .then(res => {
                    // Seta as faturas no eventBus para o outro Componente
                    const user = {
                      id: res.id,
                      ...res.data()
                    }
                    this.$store.commit("setCurrentUser", user)
                    this.$store.commit("setDrawer", true)
                    localStorage.setItem("currentUser", JSON.stringify(user))
                    this.loading = res.loading
                    this.$router.replace("/admin/maes")
                  })
                  .catch(err => {
                    console.error("Error: ", err)
                    this.loading = false
                    this.error = "Ops! Erro ao buscar dados do usuário! Contate nosso suporte."
                  })
              }
            }
        })
        .catch(error => {
          console.error("Error: ", error)
          this.loading = false
          if (error.code === "auth/wrong-password") {
            this.error = "Ops! Senha inválida."
          } else if (error.code === "auth/user-not-found") {
            this.error = "Ops! Email não encontrado em nossa base, tem certeza que se cadastrou com esse e-mail?"
          } else {
            this.error = "Ops! " + error.code + ": " + error.message
          }
        })
    }
  }
}
</script>
